import { Controller } from '@hotwired/stimulus';
import {
  csrfToken
} from '../../application/javascripts/helpers/api_requests_helper';
import {
  buildIconModal,
  largeModalIcon,
  modalText,
  closeModalButton,
  buildModalButton,
  buildModalButtonGroup
} from '../../application/javascripts/helpers/modal_builder_helper';

export default class extends Controller {
  static values = {
    errorMessage: String,
    emptyMessage: String
  }

  static defaultMessage = 'Please select an option'
  static emptyMessage = 'No sets created yet'
  static errorMessage = 'An error occurred. Please try again later.'
  static setKeys = ['name', 'value', 'ops']

  connect () {
    this.element[this.identifier] = this

    this._setDefaultView()
    this.uuid = undefined
  }

  availableSets () {
    return this.sets
  }

  async render (uuid) {
    this.uuid = uuid
    await this._handleSetsContent()
  }

  async refresh () {
    await this._handleSetsContent()
  }

  async _handleSetsContent () {
    const data = await this._fetchSetsForUUID()

    this.element.innerHTML = ''
    this.sets = data

    if (data && data.length > 0) {
      data.forEach((s) => {
        this.element.append(this._buildSet(s))
      })
    } else if (data) {
      this.element.append(this._buildEmptyMessage())
    } else {
      this.element.append(this._buildErrorMessage())
    }
  }

  _buildSet (set) {
    const el = document.createElement('div')
    el.classList.add('hexo-set')
    this.constructor.setKeys.forEach((k) => { el.append(this._buildSection(k, set)) })
    return el
  }

  _buildSection (key, set) {
    const el = document.createElement('div')

    el.classList.add('hexo-set__' + key)
    el.innerHTML = this._contentForSection(key, set)

    return el
  }

  _contentForSection (key, set) {
    let content = ''

    switch (key) {
      case 'value':
        content = set['value'].replace(/&quot;/g, '').replace(/[\[\]]/g, '')
        break;
      case 'ops':
        content = buildIconModal('close', this._deleteConfirmation(set), 'danger')
        break
      default:
        content = set[key]
    }

    return content
  }

  _deleteConfirmation (set) {
    const wrapper = document.createElement('div')

    wrapper.append(largeModalIcon('danger'))
    wrapper.append(modalText('Are you sure you want to delete this set?'))
    wrapper.append(this._buildActions(set))

    return wrapper.innerHTML
  }

  _buildActions (set) {
    const actions = buildModalButtonGroup()
    actions.dataset.controller = 'hex-protect--delete-set'
    actions.setAttribute('data-hex-protect--delete-set-url-value', '/merchant/sets/' + set['uuid'] + '?uid=' + this.uuid)

    const deleteButton = buildModalButton('Delete Set', 'danger')
    deleteButton.dataset.action = 'click->hex-protect--delete-set#delete'


    actions.append(closeModalButton('Cancel'))
    actions.append(deleteButton)

    return actions
  }

  _buildEmptyMessage () {
    const el = document.createElement('p')
    el.classList.add('text--center')
    el.innerHTML = this._emptyMessage()

    return el
  }

  _emptyMessage () {
    if (this.hasEmptyMessageValue) {
      return this.emptyMessageValue
    } else {
      return this.constructor.emptyMessage
    }
  }

  _buildErrorMessage () {
    const el = document.createElement('p')
    el.classList.add('text--center')
    el.innerHTML = this._errorMessage()

    return el
  }

  _errorMessage () {
    if (this.hasErrorMessageValue) {
      return this.errorMessageValue
    } else {
      return this.constructor.errorMessage
    }
  }

  async _fetchSetsForUUID () {
    try {
      const result = await fetch('/merchant/sets?uid=' + this.uuid, {
        headers: {
          'X-CSRF-Token': csrfToken(),
        },
        method: 'GET',
        credentials: 'include'
      })

      const data = await result.json()

      return data['sets']
    } catch (e) {
      return undefined
    }
  }

  _setDefaultView () {
    this.element.innerHTML = ''
    this.element.append(this._buildMessage())
  }

  _buildMessage () {
    const message = document.createElement('p')
    message.classList.add('text--center')
    message.innerHTML = this.constructor.defaultMessage
    return message
  }
}
